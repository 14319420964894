import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ToolsSlider from "../components/toolsSlider"
import PartnershipsSlider from "../components/partnershipsSlider"
import PostList from "../components/postList"
import ToolHeading from "../components/ToolHeading"

const ServicesDetails = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];
  const page = data.wpService;
  // console.log(page);

  return (
  <Layout HeadClass="header-inner">
    <Seo title={page.seo.title} image={page.featuredImage && page.featuredImage.node.mediaItemUrl} description={page.seo.metaDesc} focuskw={page.seo.focuskw} />
    
    <div className="media-banner overflow-hidden text-white position-relative">
      {/* <img loading="lazy" src={page.featuredImage && page.featuredImage.node.mediaItemUrl} alt={page.featuredImage && page.featuredImage.node.altText} className="w-100 media-banner-parallax d-block gatsby-image-wrapper" /> */}
      <picture>
        <source media="(min-width:1280px)" srcSet={page.featuredImage.node?.mediaItemUrl} />
        {/* <source media="(min-width:992px)" srcSet={page.featuredImage.node?.mediaDetails.sizes[2]?.sourceUrl} /> */}
        <source media="(min-width:575px)" srcSet={page.featuredImage.node?.mediaDetails.sizes[2]?.sourceUrl} />
        <img loading="lazy" width="1920" height="1080" src={page.featuredImage.node?.mediaDetails.sizes[0]?.sourceUrl} alt={page.featuredImage.node?.altText} className="w-100 media-banner-parallax d-block gatsby-image-wrapper" />
      </picture>

      <div className="container position-absolute">
        <div className="row">
          <div className="col-12" data-aos="fade-up">
              <div className="mt-md-0 mt-5">
                <div className="subtitle text-uppercase h5">OUR SERVICES</div>
                <h1 className="title position-relative mb-0" dangerouslySetInnerHTML={{__html: page.service.serviceMainPageListTitle}} />
              </div>
          </div>
        </div>
      </div>
    </div>

    <div className="py-120 pt-0">
        <div className="container">
            <div className="service-box bg-primary">
                <div data-aos="fade-up" dangerouslySetInnerHTML={{__html: page.content}} />

                <p data-aos="fade-up" className="pt-lg-5 pt-md-4 pt-3"><small>[Credit : {page.service.credit}]</small></p>
            </div>
        </div>
    </div>

    <div className="py-120 pt-5 overflow-hidden">
      <div className="container">
        <div className="row justify-content-center text-center mb-md-5 mb-4 pb-md-3">
            <div className="col-sm-11 col-md-9 col-lg-7 col-xxl-8">
              <ToolHeading />
            </div>
        </div>
      </div>

      <ToolsSlider posts={page.service.tools} />
    </div>

    <div className="py-120 pt-5 overflow-hidden">
        <div className="container">
            <div className="row justify-content-center text-center mb-md-5 mb-4 pb-md-3">
                <div className="col-sm-11 col-md-9 col-lg-7 col-xxl-8" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="h1">{pageContent?.servicePage.ourWorkInTitle} <em>{page.service.serviceMainPageListTitle}</em></h2>
                    <p dangerouslySetInnerHTML={{__html: pageContent?.servicePage.ourWorkInSubtitle}} />
                </div>
            </div>

            <PartnershipsSlider posts={page.service.ourWorks} />
        </div>
    </div>
    
    {page.service.discoverMoreAboutBlogs?.length > 0 && (
      <div className="py-120 mb-5 pt-5">
          <div className="container">
              <div className="row align-items-center justify-content-center justify-content-xl-between">
                  <div className="d-flex col-sm-10 col-xl-3 m-auto">
                      <div className="m-xl-auto w-100 mb-xl-0 mb-md-5 mb-4 d-flex flex-xl-wrap justify-content-xl-start justify-content-between">
                        {/*  data-aos="fade-up" */}
                      <h2 className="mb-xl-3 h1 w-100 mb-0">Discover <em>more <br />about</em> {page.service.serviceMainPageListTitle}</h2>
                      <Link aria-label="View all blogs" to="/blog" className="btn text-uppercase btn-link d-inline-flex align-items-center">
                          ViEW ALL

                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                          </svg>
                      </Link>
                      </div>
                  </div>
                  <div className="col-xl-6 col-sm-10">
                      <PostList posts={page.service.discoverMoreAboutBlogs} />
                  </div>
              </div>
          </div>
      </div>
    )}
  
  </Layout>
 );
}

export default ServicesDetails

export const query = graphql `
  query($slug: String) {
    allWpPage(filter: {slug: {eq: "services"}}) {
      nodes {
        servicePage {
          ourWorkInSubtitle
          ourWorkInTitle
        }
      }
    }
    wpService(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
      }
      content
      service {
        credit
        serviceMainPageListTitle
        serviceMainPageListContent
        discoverMoreAboutBlogs {
          ... on WpBlog {
            slug
            title
            featuredImage {
              node {
                sourceUrl
                altText
                mediaDetails {
                  sizes {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
        ourWorks {
          ... on WpPartnership {
            title
            featuredImage {
              node {
                altText
                mediaItemUrl
                mediaDetails {
                  sizes {
                    sourceUrl
                  }
                }
              }
            }
            partnership {
              urlOfCaseStydy
              quote
              designation
              partnerName
              partnerImage {
                altText
                mediaItemUrl
                mediaDetails {
                  sizes {
                    sourceUrl
                    width
                  }
                }
              }
            }
            content
          }
        }
        tools {
          ... on WpTool {
            slug
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          mediaDetails {
            sizes {
              sourceUrl
            }
          }
        }
      }
    }
  }
`