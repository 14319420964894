import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
import { Link } from "gatsby";

export default function PartnershipsSlider({posts}) {
  // console.log(posts);
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 5,
          stretch: 0,
          depth: 10,
          modifier: 1,
          slideShadows: false,
        }}
        breakpoints = {{
            0: {
              centeredSlides: true,
              spaceBetween: 15,
              coverflowEffect: {
                rotate: 25,
                stretch: 0,
                depth: 35,
                modifier: 1,
                slideShadows: false,
              }
            },
            768: {
              centeredSlides: false,
              spaceBetween: 40,
              coverflowEffect: {
                rotate: 5,
                stretch: 0,
                depth: 10,
                modifier: 1,
                slideShadows: false,
              }
            },
            1200: {
              centeredSlides: false,
              spaceBetween: 70,
              coverflowEffect: {
                rotate: 5,
                stretch: 0,
                depth: 15,
                modifier: 1,
                slideShadows: false,
              }
            },
            1800: {
              spaceBetween: 90,
              coverflowEffect: {
                rotate: 5,
                stretch: 2,
                depth: 15,
                modifier: 1,
                slideShadows: false,
              }
            },
          }}
        pagination={{
            clickable: true
        }}
        modules={[EffectCoverflow, Pagination]}
        className="partnerships"
      >
        {posts && posts.map((post, index) => (
          <SwiperSlide key={index}>
              <Link aria-label="Case Study" to={'/case-study/' + post.partnership.urlOfCaseStydy} className="slide-flip d-block bg-grad">
                  <h2 dangerouslySetInnerHTML={{__html: post.title}} />

                  <div className="slide-flip-image">
                      {/* <img loading="lazy" src={post.featuredImage && post.featuredImage.node.mediaItemUrl} alt={post.featuredImage && post.featuredImage.node.altText} className="img-fluid gatsby-image-wrapper" /> */}
                      {/* {post.featuredImage.node?.mediaDetails.sizes?.length > 3 ? (
                        <picture>
                            <source media="(min-width:575px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[3]?.sourceUrl} />
                            <img loading="lazy" width="561" height="400" src={post.featuredImage.node?.mediaDetails.sizes[0]?.sourceUrl} alt={post.featuredImage.node?.altText} className="img-fluid gatsby-image-wrapper" />
                        </picture>
                      ):(
                        <picture>
                            <source media="(min-width:575px)" srcSet={post.featuredImage.node?.mediaItemUrl} />
                            <img loading="lazy" width="561" height="400" src={post.featuredImage.node?.mediaDetails.sizes[0]?.sourceUrl} alt={post.featuredImage.node?.altText} className="img-fluid gatsby-image-wrapper" />
                        </picture>
                      )} */}

                    <GatsbyImage
                          image={{
                            layout: "fixed",
                            images: {
                              fallback: {
                                src: post.featuredImage.node?.mediaDetails.sizes[3] ? post.featuredImage.node?.mediaDetails.sizes[3]?.sourceUrl : post.featuredImage && post.featuredImage.node.mediaItemUrl,
                                // srcSet: getSrcSet(post.featuredImage && post.featuredImage.node.mediaItemUrl),
                              },
                            },
                          }}
                          alt={post.featuredImage && post.featuredImage.node.altText}
                        />
                  </div>

                  <div className="slide-flip-testimonial">
                  {/* <p className="text-center" dangerouslySetInnerHTML={{__html: post.content}} /> */}
                  <p className="text-center"><p dangerouslySetInnerHTML={{__html: post.partnership.quote}} /></p>
                  {/* <p className="text-center"><span dangerouslySetInnerHTML={{__html: post && post.content}}/></p> */}
                  <div className="d-flex justify-content-center align-items-center">
                      {/* <img loading="lazy" src={post.partnership.partnerImage && post.partnership.partnerImage.mediaItemUrl} alt={post.partnership.partnerImage && post.partnership.partnerImage.altText} className="gatsby-image-wrapper" /> */}
                      {/* <picture>
                          <img loading="lazy" width="95" height="95" src={post.partnership.partnerImage?.mediaDetails.sizes ? post.partnership.partnerImage?.mediaDetails.sizes[1]?.sourceUrl : post.partnership.partnerImage?.mediaItemUrl} alt={post.partnership.partnerImage?.altText} className="gatsby-image-wrapper" />
                      </picture> */}
                      <GatsbyImage
                          image={{
                            layout: "fixed",
                            images: {
                              fallback: {
                                src: post.partnership.partnerImage?.mediaDetails.sizes ? post.partnership.partnerImage?.mediaDetails.sizes[1]?.sourceUrl : post.partnership.partnerImage?.mediaItemUrl,
                                // srcSet: getSrcSet(post.featuredImage && post.featuredImage.node.mediaItemUrl),
                              },
                            },
                          }}
                          alt={post.partnership.partnerImage?.altText}
                        />
                      <p><strong dangerouslySetInnerHTML={{__html: post.partnership.partnerName}} /> {post.partnership.designation}</p>
                  </div>
                  </div>
              </Link>
          </SwiperSlide>
        ))}
        
      </Swiper>
    </>
  );
}
