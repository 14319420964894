import React from 'react'
import { graphql, StaticQuery } from "gatsby"

const ToolHeading = () => (
    <StaticQuery
    query={graphql`
      {
        allWpPage(filter: {slug: {eq: "our-tools"}}) {
            nodes {
              toolPage {
                similarToolSubtitle
                similarToolTiltle
              }
            }
        }
      }
    `}
    render={data => {
      const pageContent = data.allWpPage.nodes[0];
      return (
    <>
        <h2 className='h1'  data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html : pageContent?.toolPage.similarToolTiltle}} />
        <p  data-aos="fade-up" data-aos-delay="400"  dangerouslySetInnerHTML={{__html : pageContent?.toolPage.similarToolSubtitle}} />
    </>
 );
}}
></StaticQuery>
);

export default ToolHeading;
